const bftiExternal = '/bfti-panel/research-and-other-request/'
export const bftiResearchRequestListApi = bftiExternal + 'list'
export const bftiResearchRequestStoreApi = bftiExternal + 'store'
export const bftiResearchRequestUpdateApi = bftiExternal + 'update'
export const bftiResearchRequestApproveRejectApi = bftiExternal + 'approve-reject'
export const bftiResearchRequestForwardApi = bftiExternal + 'forward'
export const bftiResearchRequestForwardAdminList = bftiExternal + 'forward-request-list'
export const bftiResearchRequestApproveApi = bftiExternal + 'approve'
export const bftiResearchRequestRejectApi = bftiExternal + 'reject'
export const bftiResearchRequestForwardTrackApi = bftiExternal + 'forward-track'
export const getForwardCommentsApi = bftiExternal + 'forward-comments-list'

const bfti = '/research-and-other/approved-research-and-other-request/'
export const bftiResearchRequestReceivedList = bftiExternal + 'received-list'
export const bftiResearchRequestAdminList = bftiExternal + 'admin-list'
export const bftiResearchRequestApprovedList = bfti + 'list'

// Contract Signing Routes...
const contractSigning = '/contract-sign/contract-signing/'
export const contractSigningList = contractSigning + 'list'
export const contractResearchList = contractSigning + 'research-list'
export const contractSigningUpdate = contractSigning + 'update'
export const contractSignDetailsApi = contractSigning + 'details'
export const contractSignEditApi = contractSigning + 'edit'

export const userListByDesignWise = '/user-management/user/list-by-designation'

const assignTaskCoordinator = '/research-plan-preparation/assign-task-coordinator/'
export const assignTaskCoordinatorList = assignTaskCoordinator + 'list'
export const assignTaskCoordinatorStore = assignTaskCoordinator + 'store'
export const assignTaskCoordinatorUpdate = assignTaskCoordinator + 'update'
export const assignTaskCoordinatorTogle = assignTaskCoordinator + 'toggle-status'
export const assignTaskCoordinatorShow = assignTaskCoordinator + 'show'

export const userListByDesignationApi = 'user/users-by-designation-id'
